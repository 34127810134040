import { useState, useEffect } from 'react';
import Splash from "../components/Splash";
import bgImg from "../images/Top-Image-Linde-e1638150520682.jpg";
import Tab from "../components/Tab";
import { Link } from "react-router-dom";

function LindePage() {
    const [activeTab, setActiveTab] = useState("Electric Counterbalanced");
    const [equipmentData, setEquipmentData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://www.hurricanelift.com:5000/equipment?vendor=Linde');

                if (!response.ok) {
                    console.log('Error fetching data');
                } else {
                    const data = await response.json();

                    const equipmentByType = {
                        Counterbalanced: data.equipment.filter((equipment) => equipment.type === 'Counterbalanced'),
                        Narrow: data.equipment.filter((equipment) => equipment.type === 'Narrow Aisle'),
                        Warehouse: data.equipment.filter((equipment) => equipment.type === 'Warehouse'),
                        Cushion: data.equipment.filter((equipment) => equipment.type === 'Cushion Tires'),
                        Pneumatic: data.equipment.filter((equipment) => equipment.type === 'Pneumatic Tires'),
                        Tow: data.equipment.filter((equipment) => equipment.type === 'Tow Truck'),
                    };

                    setEquipmentData(equipmentByType);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);


    const text = <h1 className="text-6xl xl:text-7xl 2xl:text-9xl uppercase text-white font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">Linde</h1>;

    return (
        <div>
            <Splash image={bgImg} text={text} />
            <div className='mx-auto flex items-center justify-center'>
                <Tab name={"Electric Counterbalanced"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Electric Narrow Aisle"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Electric Warehouse"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Int Combustion Cushion Tires"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Int Combustion Pneumatic Tire"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Tow Trucks"} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div>
                {activeTab === "Electric Counterbalanced" && equipmentData['Counterbalanced'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Counterbalanced'] &&
                            equipmentData['Counterbalanced'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/linde/${equipment.link}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
                {activeTab === "Electric Narrow Aisle" && equipmentData['Narrow'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Narrow'] &&
                            equipmentData['Narrow'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/linde/${equipment.link}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
                {activeTab === "Electric Warehouse" && equipmentData['Warehouse'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Warehouse'] &&
                            equipmentData['Warehouse'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/linde/${equipment.link}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
                {activeTab === "Int Combustion Cushion Tires" && equipmentData['Cushion'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Cushion'] &&
                            equipmentData['Cushion'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/linde/${equipment.link}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
                {activeTab === "Int Combustion Pneumatic Tire" && equipmentData['Pneumatic'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Pneumatic'] &&
                            equipmentData['Pneumatic'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/linde/${equipment.link}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
                {activeTab === "Tow Trucks" && equipmentData['Tow'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Tow'] &&
                            equipmentData['Tow'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/linde/${equipment.link}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
            </div>
        </div>
    );
}

export default LindePage;