import Slider from 'react-slick';
import hyundai from '../images/Top-Image-hyundai-e1638150566478.jpg';
import linde from '../images/Top-Image-Linde-e1638150520682.jpg';
import loadlifter from '../images/LoadLifter-Banner.jpg';

function SplashCarousel() {

    const images = [
        hyundai,
        linde,
        loadlifter,
    ];

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: "linear",
        swipe: false,
        arrows: false,
      };

    // const slides = images.map((image, index) => (
    //     <div className="uppercase text-white w-screen" key={index}>
    //         {index === 0 && (<div className="flex justify-center w-screen" style={{background: `url(${image}) no-repeat center center fixed`, backgroundSize: 'cover', height: '60vh', width: '100%'}}>
    //             <div className="z-10" style={{paddingRight: '40%', paddingTop:'17vh'}}>
    //                 <h3 className="font-bold mb-2 text-6xl">Hyundai<br/>Forklifts</h3>
    //                 <p className="text-3xl">Get real</p>
    //                 <p className="font-bold text-4xl">Get Hyundai</p>
    //             </div>
    //             <div className="fixed inset-0 bg-black opacity-10"></div>
    //         </div>)}
    //         {index === 1 && (<div className="flex justify-center w-screen" style={{background: `url(${image}) no-repeat center center fixed`, backgroundSize: 'cover',  height: '60vh', width: '100%'}}>
    //             <div className="z-10" style={{paddingRight: '40%', paddingTop:'17vh'}}>
    //                 <h3 className="font-bold mb-2 text-6xl">Linde<br/>Forklifts</h3>
    //                 <p className="text-4xl">Trusted Worldwide</p>
    //             </div>
    //             <div className="fixed inset-0 bg-black opacity-10"></div>
    //         </div>)}
    //         {index === 2 && (<div className="flex justify-center w-screen" style={{background: `url(${image}) no-repeat center center fixed`, backgroundSize: 'cover',  height: '60vh', width: '100%'}}>
    //             <div className="z-10" style={{paddingRight: '40%', paddingTop:'17vh'}}>
    //                 <h3 className="font-bold mb-2 text-6xl">Loadlifter</h3>
    //                 <p className="text-4xl">Quality Driven</p>
    //                 <p className="font-bold text-4xl">Quality Delivered</p>
    //             </div>
    //             <div className="fixed inset-0 bg-black opacity-10"></div>
    //         </div>)}
    //     </div>
    // ));

    const slides = images.map((image, index) => (
        <div className="uppercase text-white w-full" key={index}>
            <div 
                className="flex items-center justify-start w-full bg-center bg-no-repeat bg-cover relative h-[32rem]" 
                style={{backgroundImage: `url(${image})`}}
            >
                <div className="z-10 px-10 sm:px-20">
                    <h3 className="font-bold mb-2 text-3xl sm:text-4xl md:text-6xl">
                        {index === 0 ? "Hyundai Forklifts" : index === 1 ? "Linde Forklifts" : "Loadlifter"}
                    </h3>
                    <p className="text-2xl sm:text-3xl md:text-4xl">
                        {index === 0 ? "Get real" : index === 1 ? "Trusted Worldwide" : "Quality Driven"}
                    </p>
                    {index !== 1 && (
                        <p className="font-bold text-2xl sm:text-3xl md:text-4xl">
                            {index === 0 ? "Get Hyundai" : "Quality Delivered"}
                        </p>
                    )}
                </div>
                <div className="absolute inset-0 bg-black opacity-10"></div>
            </div>
        </div>
    ));

    return (
        <div className=" w-screen">
            <Slider {...settings}>
                {slides}
            </Slider>
        </div>
    );
}

export default SplashCarousel;