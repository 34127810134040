import NewArrival from "./NewArrival";
import img1 from '../images/Hyundai-35DA-9-2019.jpg';
import img2 from '../images/LR_35_40_45D-9-50DA-9_front-left-view.png';
import img3 from '../images/1-Hyundai-Electric-101315-600x600.jpg';

function NewArrivals() {

    const name1 = 'Hyundai Diesel 25, 30, 33d, 35DA-9';
    const name2 = 'Hyundai Diesel 35, 40, 45D-9, 50DA-9';
    const name3 = 'Hyundai Electric 10, 13, 15 BTR-9';

    const link1 = '/hyundai/25-30-33d-35da-9';
    const link2 = '/hyundai/35-40-45d-9-50da-9';
    const link3 = '/hyundai/10-13-15-btr-9';

    return (
        <div className="flex flex-col items-center my-5">
            <h1 className="uppercase font-bold text-4xl">New Arrivals</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 my-5 gap-5">
                <NewArrival image={img1} name={name1} link={link1} />
                <NewArrival image={img2} name={name2} link={link2} />
                <NewArrival image={img3} name={name3} link={link3} />
            </div>
        </div>
    );
}

export default NewArrivals;