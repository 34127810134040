import React from 'react';
import NavMenuItem from './NavMenuItem';
import NavDropdown from './NavDropdown';
import { useNavigate, Link } from 'react-router-dom';
import { GoThreeBars } from 'react-icons/go';
import { useState } from 'react';

function NavMenu() {

  const [showNavMenu, setShowNavMenu] = useState(false);

  const navigate = useNavigate();

  const handleSelect = (option) => {
      console.log(option.value);
      toggleShowNavMenu();
      navigate(option.value);
  }

  const toggleShowNavMenu = () => {
    setShowNavMenu(!showNavMenu);
  }

  const closeNavMenu = () => {
    setShowNavMenu(false);
  }

  const aboutText = <div>About</div>;
  
  const aboutOptions = [
    { label: 'About Us', value: '/about-us' },
    { label: 'Contact Us', value: '/contact-us' },
  ];

  const equipmentText = <div>Equipment</div>;
  
  const equipmentOptions = [
    { label: 'Hyundai', value: '/hyundai' },
    { label: 'Linde', value: '/linde' },
    { label: 'Load Lifter', value: '/loadlifter' },
    { label: 'Blue Giant', value: '/bluegiant' },
    { label: 'Used', value: '/used' },
  ];

  const servicePartsText = <div>Service & Parts</div>;
  
  const servicePartsOptions = [
    { label: 'Repairs & Service', value: '/repairsandservice' },
    { label: 'Parts', value: '/parts' },
  ];

  const contactUsText = <div>Contact Us</div>;
  
  const contactUsOptions = [
    { label: 'Contact Us', value: '/contact-us' },
    { label: 'Staff Directory', value: '/staffdirectory' },
  ];

  return (
    <div className="w-screen bg-hurricane-orange font-bold uppercase text-white">
      <ul className="navbar-nav ml-auto flex flex-col lg:flex-row lg:items-center gap-2">
        <li className="flex col justify-between">
          <Link to="/" onClick={closeNavMenu} className="text-white">HURRICANE <span className="text-hurricane-orange bg-white px-2">LIFT TRUCK</span></Link>
          <GoThreeBars className="text-2xl lg:hidden" onClick={toggleShowNavMenu} />
        </li>
        <div className={`${showNavMenu ? 'block' : 'hidden'} lg:flex justify-between items-center`}>
          <li>
          <NavDropdown options={aboutOptions} onChange={handleSelect} className="mx-4">
            {"About"}
          </NavDropdown>
          </li>
          <li>
          <NavDropdown options={equipmentOptions} onChange={handleSelect} className="mx-4">
            {"Equipment"}
          </NavDropdown>
          </li>
          <li>
          <NavDropdown options={servicePartsOptions} onChange={handleSelect} className="mx-4">
            {"Service & Parts"}
          </NavDropdown>
          </li>
          <li>
          <div className="mx-4 py-3"><Link to="/rentals" onClick={closeNavMenu}>Rentals</Link></div>
          </li>
          <li>
          <div className="mx-4 py-3"><Link to="/training" onClick={closeNavMenu}>Training</Link></div>
          </li>
          <li>
          <NavDropdown options={contactUsOptions} onChange={handleSelect} className="mx-4">
            {contactUsText}
          </NavDropdown>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default NavMenu;