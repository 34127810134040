import { useState, useEffect } from 'react';
import Splash from "../components/Splash";
import bgImg from "../images/BlueGiantBanner.jpg";
import Tab from "../components/Tab";
import { Link } from "react-router-dom";

function BlueGiantPage() {
    const [activeTab, setActiveTab] = useState("Manual Pallet Trucks");
    const [equipmentData, setEquipmentData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://www.hurricanelift.com:5000/equipment?vendor=Blue%20Giant');

                if (!response.ok) {
                    console.log('Error fetching data');
                } else {
                    const data = await response.json();

                    const equipmentByType = {
                        Manual: data.equipment.filter((equipment) => equipment.type === 'Manual'),
                        Powered: data.equipment.filter((equipment) => equipment.type === 'Powered'),
                        Task: data.equipment.filter((equipment) => equipment.type === 'Task Support'),
                        Rider: data.equipment.filter((equipment) => equipment.type === 'Rider'),
                        Walkie: data.equipment.filter((equipment) => equipment.type === 'Walkie'),
                    };

                    setEquipmentData(equipmentByType);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);


    const text = <h1 className="text-6xl xl:text-7xl 2xl:text-9xl uppercase text-white font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">Blue Giant</h1>;

    return (
        <div>
            <Splash image={bgImg} text={text} />
            <div className='mx-auto flex items-center justify-center'>
                <Tab name={"Manual Pallet Trucks"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Powered Pallet Trucks"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Task Support Vehicles"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Rider Pallet Trucks"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Walkie Stackers"} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div>
                {activeTab === "Manual Pallet Trucks" && equipmentData['Manual'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Manual'] &&
                            equipmentData['Manual'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/bluegiant/${equipment.link.replace(/\//g, '--')}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
                {activeTab === "Powered Pallet Trucks" && equipmentData['Powered'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Powered'] &&
                            equipmentData['Powered'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/bluegiant/${equipment.link.replace(/\//g, '--')}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
                {activeTab === "Task Support Vehicles" && equipmentData['Task'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Task'] &&
                            equipmentData['Task'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/bluegiant/${equipment.link.replace(/\//g, '--')}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
                {activeTab === "Rider Pallet Trucks" && equipmentData['Rider'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Rider'] &&
                            equipmentData['Rider'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/bluegiant/${equipment.link.replace(/\//g, '--')}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
                {activeTab === "Walkie Stackers" && equipmentData['Walkie'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Walkie'] &&
                            equipmentData['Walkie'].map((equipment) => (
                                <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                    <Link to={`/bluegiant/${equipment.link.replace(/\//g, '--')}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                        <img src={`data:image/jpeg;base64,${equipment.image}`} alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                        {equipment.name}
                                    </Link>
                                </div>
                            ))}
                    </div>
                }
            </div>
        </div>
    );
}

export default BlueGiantPage;