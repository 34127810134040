import NewArrivals from "../components/NewArrivals";
import SplashCarousel from "../components/SplashCarousel";
import img1 from '../images/factory2.jpg';
import img2 from '../images/factory3.jpg';
import ImageZoom from "../components/ImageZoom";

function MainPage() {
    return (
        <div>
            <SplashCarousel />
            <div>
                <NewArrivals />
            </div>
            <div className="flex flex-row items-center justify-center space-x-4">
                <ImageZoom className="my-5 w-auto h-auto max-w-[500px] max-h-[500px]" src={img1} alt ="img1"/>
                <ImageZoom className="my-5 w-auto h-auto max-w-[500px] max-h-[500px]" src={img2} alt ="img2"/>
            </div>
            <div className="grid grid-rows-3 gap-0 place-items-center mb-5">
                <h3 className="uppercase text-3xl font-bold">Hurricane Lift Truck</h3>
                <hr className="border border-hurricane-orange bg-hurricane-orange my-2.5 mx-3 h-1.5" style={{width:'40%'}} />
                <p className="flex mx-20">Established in 1991, Hurricane is aligned with recognized word class suppliers, Hyundai, Linde, Blue Giant and Loadlifter. We specialize in sales, leases, rentals, operator training, parts and service of all brands of lift trucks. Located in Dartmouth, Nova Scotia, we have branches in Saint John, New Brunswick and St. John’s, Newfoundland. We are a full-service dealership, stocking over 22,000 SKU’s in our parts department with next day delivery throughout Atlantic Canada. We operate 12+ fully stocked, GPS equipped service vans. Our service technicians use smart phone technology to open and clear calls, find parts within our network, and embed videos and pictures of lift truck deficiencies within your service quote. Our proprietary software allows us to monitor and manage some of the largest lift truck fleets in Atlantic Canada.</p>
            </div>
            <div>

            </div>
        </div>
    );
}

export default MainPage;