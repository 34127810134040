import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

function HyundaiEquipmentPage() {
    const { productId } = useParams();
    const [iframeSrc, setIframeSrc] = useState('');
    const proxyUrl = 'https://www.hurricanelift.com:5000/proxy';
    //const targetUrl = 'http://load-liftermfg.com/products/4400-series';
    const targetUrl = `https://www.hyundaiforkliftamericas.com/forklifts/${productId}/`;
    useEffect(() => {
        fetch(proxyUrl + '?url=' + encodeURIComponent(targetUrl))
        .then((response) => {
            return response.text();
        })
        .then((content) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');

            console.log(doc)

            // Remove the elements you want
            const header = doc.querySelector('header');
            const hero = doc.querySelector('.hero-variant');
            const locator = doc.querySelector('.page-block.locator')
            const footer = doc.querySelector('footer');

            if (header) header.remove();
            if (hero) hero.remove();
            if (locator) locator.remove();
            if (footer) footer.remove();

            // Serialize the modified DOM object back to a string
            const serializer = new XMLSerializer();
            const modifiedContent = serializer.serializeToString(doc);

            const blob = new Blob([modifiedContent], { type: 'text/html' });
            const blobUrl = URL.createObjectURL(blob);
            setIframeSrc(blobUrl);
        })
        .catch((error) => {
            console.error(`Problem with fetch request: ${error.message}`);
            return (<div></div>);
        });
    }, []);

    return (
        <iframe
            title="fetched-content"
            src={iframeSrc}
            style={{ height: '100vh', width: '66%', border: 'none' }}
            className="mx-auto my-10 mt-30"
        ></iframe>
  );
}

export default HyundaiEquipmentPage;